import { default as indexsiXIq6MmkPMeta } from "/app/src/pages/[hub-slug]/[content-slug]/index.vue?macro=true";
import { default as index3QTgJQYbitMeta } from "/app/src/pages/[hub-slug]/index.vue?macro=true";
import { default as indexTFNZjEznedMeta } from "/app/src/pages/auth/facebook/index.vue?macro=true";
import { default as indexDXTVXVvOeTMeta } from "/app/src/pages/auth/instagram/index.vue?macro=true";
import { default as indexyLNW09wAdVMeta } from "/app/src/pages/auth/linkedin/index.vue?macro=true";
import { default as indexa2UUcMbXF1Meta } from "/app/src/pages/auth/login/index.vue?macro=true";
import { default as indexpMsS1YIVv6Meta } from "/app/src/pages/auth/logout/index.vue?macro=true";
import { default as indexwOyh5CkCgVMeta } from "/app/src/pages/auth/register/index.vue?macro=true";
import { default as indexHD5rwuoeViMeta } from "/app/src/pages/auth/resend-welcome-email/index.vue?macro=true";
import { default as indexaEzulFyTzNMeta } from "/app/src/pages/auth/reset-password/index.vue?macro=true";
import { default as index7LBuFvAcTuMeta } from "/app/src/pages/auth/set-new-password/index.vue?macro=true";
import { default as indexKj7pqij9IXMeta } from "/app/src/pages/auth/twitter/index.vue?macro=true";
import { default as indexFNxkyHfcICMeta } from "/app/src/pages/budgets/index.vue?macro=true";
import { default as indexT2G6UjfCh7Meta } from "/app/src/pages/customizable-content/[content-slug]/index.vue?macro=true";
import { default as indexEcI7h8hYbIMeta } from "/app/src/pages/customizable-content/index.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as indexaIDH5MVG4zMeta } from "/app/src/pages/my-customized-content/index.vue?macro=true";
import { default as indexw8gJBeQQwjMeta } from "/app/src/pages/my-posts/index.vue?macro=true";
import { default as index9ljtvUpsWzMeta } from "/app/src/pages/oauth2/facebook/index.vue?macro=true";
import { default as indextB0FK0MOXKMeta } from "/app/src/pages/oauth2/instagram/index.vue?macro=true";
import { default as index1ZY3BK5EtfMeta } from "/app/src/pages/oauth2/linkedin/index.vue?macro=true";
import { default as indexnIp1wGQFaNMeta } from "/app/src/pages/oauth2/twitter/index.vue?macro=true";
import { default as indexXlc6iwPQX5Meta } from "/app/src/pages/onboarding/index.vue?macro=true";
import { default as indexcMg18w3zKnMeta } from "/app/src/pages/org/[organization-slug]/[hub-slug]/[content-slug]/index.vue?macro=true";
import { default as indexF6fj9MiTmUMeta } from "/app/src/pages/org/[organization-slug]/[hub-slug]/index.vue?macro=true";
import { default as indexqlr3PlMXhcMeta } from "/app/src/pages/org/[organization-slug]/index.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as indexI6fkPdKqlNMeta } from "/app/src/pages/promote/_constants/index.ts?macro=true";
import { default as indexQHs3qeZZKdMeta } from "/app/src/pages/promote/_types/index.ts?macro=true";
import { default as indexJ11t7S7OxJMeta } from "/app/src/pages/promote/index.vue?macro=true";
import { default as indexxYbHvX0LQTMeta } from "/app/src/pages/welcome-screen/index.vue?macro=true";
export default [
  {
    name: "hubslug-contentslug",
    path: "/:hubslug()/:contentslug()",
    component: () => import("/app/src/pages/[hub-slug]/[content-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "hubslug",
    path: "/:hubslug()",
    component: () => import("/app/src/pages/[hub-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-facebook",
    path: "/auth/facebook",
    meta: indexTFNZjEznedMeta || {},
    component: () => import("/app/src/pages/auth/facebook/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-instagram",
    path: "/auth/instagram",
    meta: indexDXTVXVvOeTMeta || {},
    component: () => import("/app/src/pages/auth/instagram/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-linkedin",
    path: "/auth/linkedin",
    meta: indexyLNW09wAdVMeta || {},
    component: () => import("/app/src/pages/auth/linkedin/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: indexa2UUcMbXF1Meta || {},
    component: () => import("/app/src/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: indexpMsS1YIVv6Meta || {},
    component: () => import("/app/src/pages/auth/logout/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: indexwOyh5CkCgVMeta || {},
    component: () => import("/app/src/pages/auth/register/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-resend-welcome-email",
    path: "/auth/resend-welcome-email",
    meta: indexHD5rwuoeViMeta || {},
    component: () => import("/app/src/pages/auth/resend-welcome-email/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: indexaEzulFyTzNMeta || {},
    component: () => import("/app/src/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-set-new-password",
    path: "/auth/set-new-password",
    meta: index7LBuFvAcTuMeta || {},
    component: () => import("/app/src/pages/auth/set-new-password/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-twitter",
    path: "/auth/twitter",
    meta: indexKj7pqij9IXMeta || {},
    component: () => import("/app/src/pages/auth/twitter/index.vue").then(m => m.default || m)
  },
  {
    name: "budgets",
    path: "/budgets",
    component: () => import("/app/src/pages/budgets/index.vue").then(m => m.default || m)
  },
  {
    name: "customizable-content-contentslug",
    path: "/customizable-content/:contentslug()",
    component: () => import("/app/src/pages/customizable-content/[content-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "customizable-content",
    path: "/customizable-content",
    component: () => import("/app/src/pages/customizable-content/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "my-customized-content",
    path: "/my-customized-content",
    component: () => import("/app/src/pages/my-customized-content/index.vue").then(m => m.default || m)
  },
  {
    name: "my-posts",
    path: "/my-posts",
    meta: indexw8gJBeQQwjMeta || {},
    component: () => import("/app/src/pages/my-posts/index.vue").then(m => m.default || m)
  },
  {
    name: "oauth2-facebook",
    path: "/oauth2/facebook",
    meta: index9ljtvUpsWzMeta || {},
    component: () => import("/app/src/pages/oauth2/facebook/index.vue").then(m => m.default || m)
  },
  {
    name: "oauth2-instagram",
    path: "/oauth2/instagram",
    meta: indextB0FK0MOXKMeta || {},
    component: () => import("/app/src/pages/oauth2/instagram/index.vue").then(m => m.default || m)
  },
  {
    name: "oauth2-linkedin",
    path: "/oauth2/linkedin",
    meta: index1ZY3BK5EtfMeta || {},
    component: () => import("/app/src/pages/oauth2/linkedin/index.vue").then(m => m.default || m)
  },
  {
    name: "oauth2-twitter",
    path: "/oauth2/twitter",
    meta: indexnIp1wGQFaNMeta || {},
    component: () => import("/app/src/pages/oauth2/twitter/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexXlc6iwPQX5Meta || {},
    component: () => import("/app/src/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "org-organizationslug-hubslug-contentslug",
    path: "/org/:organizationslug()/:hubslug()/:contentslug()",
    meta: indexcMg18w3zKnMeta || {},
    component: () => import("/app/src/pages/org/[organization-slug]/[hub-slug]/[content-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "org-organizationslug-hubslug",
    path: "/org/:organizationslug()/:hubslug()",
    meta: indexF6fj9MiTmUMeta || {},
    component: () => import("/app/src/pages/org/[organization-slug]/[hub-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "org-organizationslug",
    path: "/org/:organizationslug()",
    meta: indexqlr3PlMXhcMeta || {},
    component: () => import("/app/src/pages/org/[organization-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexSJNeMkG0qzMeta || {},
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "promote-_constants",
    path: "/promote/_constants",
    component: () => import("/app/src/pages/promote/_constants/index.ts").then(m => m.default || m)
  },
  {
    name: "promote-_types",
    path: "/promote/_types",
    component: () => import("/app/src/pages/promote/_types/index.ts").then(m => m.default || m)
  },
  {
    name: "promote",
    path: "/promote",
    component: () => import("/app/src/pages/promote/index.vue").then(m => m.default || m)
  },
  {
    name: "welcome-screen",
    path: "/welcome-screen",
    meta: indexxYbHvX0LQTMeta || {},
    component: () => import("/app/src/pages/welcome-screen/index.vue").then(m => m.default || m)
  }
]